@media screen and (max-width: 1540px) {
  .res-adjst-body {
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1440px) {
  .wrap-box.active .points {
    width: 90px;
    height: 90px;
  }
  .wrap-box.active .points span {
    line-height: 1.9;
  }
  .wrap-box.active .points-content {
    font-size: 38px;
  }
  .normal-points {
    width: 90px;
    height: 90px;
  }
  .normal-points span {
    line-height: 1.7;
  }
  .normal-points-content p {
    line-height: 2.3;
    font-size: 38px;
  }
  .wrap-box:hover .normal-points {
    width: 90px;
    height: 90px;
  }
}
@media screen and (max-width: 1426px) {
  .home-icon {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1420px) {
  .home-img-box {
    overflow: hidden;
  }
  .home-img-box img {
    max-width: 100%;
  }
  .ongoing-info {
    float: none;
    padding: 15px;
    width: 50%;
  }
}

@media screen and (max-width: 1366px) {
  .wrap-box.active .points {
    width: 80px;
    height: 80px;
  }
  .wrap-box.active .points-content {
    font-size: 33px;
  }
  .wrap-box.active .points span {
    line-height: 2.4;
  }
  .wrap-box.active .points span {
    font-size: 33px;
  }
  .normal-points {
    width: 80px;
    height: 80px;
  }
  .wrap-box:hover .normal-points {
    width: 80px;
    height: 80px;
  }
  .normal-points span {
    line-height: 2.1;
    font-size: 33px;
  }
  .normal-points-content p {
    line-height: 2.4;
    font-size: 33px;
  }
  .card2 {
    width: 70%;
  }
  .img-holder {
    float: none;
    width: 100%;
  }
  .ongoing-info {
    float: none;
    width: 100%;
  }
  .home-img-box {
    overflow: hidden !important;
  }
  .home-img-box img {
    max-width: 100% !important;
  }
  .cmp-add {
    display: table;
  }
}

@media screen and (max-width: 1327px) {
  .net-adjust-inner {
    float: none;
  }
  .net-ajusted-box .offer {
    float: none;
  }
}

@media screen and (max-width: 1300px) {
  aside {
    margin-right: 5px;
  }
  .cmp-add {
    display: table;
  }
}

@media screen and (max-width: 1220px) {
  .card2 {
    width: 67%;
  }
  .add-to-list-box {
    width: 100%;
  }
  .offer span {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1150px) {
  .view-btn,
  .add-btn {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1135px) {
  .card2 {
    width: 65%;
  }
}
@media screen and (max-width: 1060px) {
  .card2 {
    width: 63%;
  }
  .adjustment-box label {
    width: 100%;
    margin-bottom: 15px;
  }
  .rmv-btn {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1060px) {
  .right-block {
    padding: 50px;
  }
}
