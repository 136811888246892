@import "../../../Common/styles/main.scss";
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: $deep-indigo;
  background-color: $text-white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: $coral;
}

.StripeElement--webkit-autofill {
  background-color: $ivory !important;
}
.cardDetail {
  color: $navy-blue !important;
  font-size: 20px;
  padding-left: 3px;
}
.cardTitle {
  color: $navy-blue !important;
  font-size: 14px;
  padding-bottom: 14px;
}
