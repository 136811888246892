@import "../../Common/styles/main.scss";
.contactus-page {
  .buisness-form-main {
    padding: 20px;
    border: 1px solid $blue-color;
    margin-top: 25px;
    max-width: 800px;
    @media (max-width: 576px) {
      margin-top: 10px;
    }
    .parts {
      width: 50%;
      margin-right: 20px;
      &:last-child {
        margin-right: unset;
      }
    }
    .row-container {
      margin-top: 10px;
      .errorText {
        text-align: left;
        color: $red-color;
        font-size: 12px;
      }
    }
    input,
    textarea {
      border: 1px solid $blue-color;
    }
    .temp {
      border: 1px solid $blue-color;
    }
    textarea {
      height: 100px !important;
    }
    label {
      text-align: left !important;
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $black-color;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    h3 {
      font-weight: 800;
      color: $blue-color;
    }
    .form-group {
      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
      .form-control {
        border: 1px solid $blue-color;
        border-radius: 5px;
        box-shadow: unset;
        outline: unset;
        height: 48px;
        font-size: 18px;
        @media (max-width: 576px) {
          font-size: 14px;
          height: 38px;
        }
      }

      textarea {
        height: 100px !important;
      }
    }
  }
  .Blue-btn {
    background: $little-light-blue-color;
    border-radius: 4px;
    color: $text-white;
    font-size: 14px;
    height: 42px;
    width: 100%;
    max-width: 150px;
    cursor: pointer;
  }
  .image-preview {
    height: 150px !important;
    padding: 10px;
    overflow: auto;
    .AllImgWrap {
      display: flex;
      flex-wrap: wrap;
      .ImgWrap {
        position: relative;
        img {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        svg {
          cursor: pointer;
          position: absolute;
          top: -2px;
          right: 9px;
          background-color: $text-white;
          border-radius: 50%;
          padding: 3px;
          font-size: 24px;
        }
      }
    }
  }
}
