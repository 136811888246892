@import "../../Common/styles/main.scss";
.header-page {
  .user-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $red-shade-white-color;
    border: 2px solid black;
  }
  .menu {
    min-height: 60px;
  }
  .nav-link {
    color: $blue-color !important;
    font-weight: 500;
  }
  .activeNav {
    color: $pink-color !important;
    font-weight: 500;
  }
  .dropdown {
    color: $blue-color;
    a {
      color: $blue-color !important;
    }
  }
  a:hover {
    color: $pink-color !important;
    font-weight: 500;
  }
  .pinkBtn {
    background-color: $pink-color !important;
    border: 1px solid $pink-color !important;
  }
  .pinkBtn:hover {
    background-color: $text-white !important;
    color: $pink-color !important;
    border: 1px solid $pink-color !important;
  }
}
