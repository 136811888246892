@import "../../Common/styles/main.scss";
.aboutus-main {
  padding: 50px 0 0 0;
  .c-container {
    max-width: 900px;
  }

  .aboutus-inner {
    margin-bottom: 50px;
    @media (max-width: 575px) {
      margin-bottom: 30px;
    }
    span {
      color: $blue-color;
      font-size: 18px;
      font-weight: 700;
    }
    h2 {
      font-size: 55px;
      font-weight: 800;
      color: $blue-color;
      margin-bottom: 15px;
      @media (max-width: 991px) {
        font-size: 38px;
      }
      @media (max-width: 576px) {
        font-size: 28px;
      }
    }
    p {
      color: $black-color;
      font-size: 16px;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
  .aboutus-inner-second {
    background: linear-gradient(
      180deg,
      rgba(235, 235, 235, 0) 0%,
      #ebedff 36.05%,
      rgba(235, 235, 235, 0) 100%
    );
    border-radius: 0px;
    p {
      margin: 0 auto;
    }
    .about-signin {
      margin: 50px 0;
      @media (max-width: 576px) {
        margin: 25px 0;
      }

      .btn {
        width: 100%;
        max-width: 150px;
      }
    }
  }
}
.AboutUsImageContainer {
  width: 500px;
  height: 550px;
  margin: 0 auto;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
