@import "../../Common/styles/main.scss";
.c-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.footer-block {
  background-color: $little-light-blue-color;
  padding: 40px 0px 10px 0px;
  color: $text-white;
  text-align: left;

  p {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .social-icons {
    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      display: flex;
      li {
        cursor: pointer;
        margin-right: 5px;
        a {
          &:hover {
            opacity: 0.2;
            cursor: pointer;
          }
        }
      }
    }
  }
  .footer-links {
    display: flex;
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    @media (max-width: 576px) {
      display: block;
    }
  }
  .quick-links {
    text-align: left;
    width: 100%;
    @media (max-width: 576px) {
      margin-top: 20px;
      display: block;
    }
    h4 {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    ul {
      li {
        list-style-type: none;
        padding: 0;
        margin: 0;
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $text-white;
          text-decoration: none;
          &:hover {
            color: $pink-color;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border-top: 1px solid#4B4EB7;
    margin-top: 30px;
  }
}
