$super-small-font: 12px;
$small-font: 14px;
$midium-font: 22px;
$large-font: 40px;

$auth-primary-color: #3e8080;
$dark-gray: #999999;
$gray-color: #9fa2b4;
$black-text-color: #393939;
$text-white: #ffffff;
$pink-color: #ff05e8;
$blue-color: #1d22a9;
$little-light-blue-color: #1a28a2;
$red-color: #ff0000;
$remove-red: #f83131;
$dark-blue: #282c34;

$black-color: #000000; //000
$little-light-black-color: #040404;
$red-shade-gray-color: #aeaeae80;
$blue-shade-gray-color: #e8e8ea;
$blue-shade-white-color: #e7e7ff;
$red-shade-black-color: #212121;
$red-shade-white-color: #d9d9d9;
$purple-color: #5d34a5;
$light-purple-color: #9395d6;
$custom-color: #1be042;
$gray-light: #e9ecef;
$dark-blue-transparent: #00000080;
$blue-gray: #495057;
$darker-gray: #555555;
$navy-blue: #090c56;
$ivory: #fefde5;
$coral: #fa755a;
$deep-indigo: #32325d;
$light-gray-2: #bbbbbb;
$light-gray-3: #f1f1f1;
$light-gray-4: #cccccc;

$gray-light-1: #e9ecea;
$dark-gray-1: #393812;
$slate-gray: #3e3e3e;
$light-gray: #fbfcfc;
$light-gray-5: #d5d5d5;
$blue-gray-1: #707070;
$pale-blue: #f5f5fb;
$pale-blue-1: #f4f8fb;
$light-gray-7: #aaa;
$light-gray-8: #ddd;
$light-gray-9: #e0e0e0;
