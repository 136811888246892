@import "./variables.scss";
.viewbox-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black-color;
  z-index: 700;
}
.viewbox-body {
  position: absolute;
  top: 50%;
  left: 50%;
  background: $text-white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  overflow: auto;
}
.viewbox-header {
  margin: 10px;
}
.viewbox-content {
  margin: 10px;
  width: 300px;
  height: 300px;
}
.viewbox-footer {
  margin: 10px;
}
.viewbox-content .viewbox-image {
  width: 100%;
  height: 100%;
}

/* buttons */
.viewbox-button-default {
  cursor: pointer;
  height: 64px;
  width: 64px;
}
.viewbox-button-default > svg {
  width: 100%;
  height: 100%;
  background: inherit;
  fill: inherit;
  pointer-events: none;
  transform: translateX(0px);
}
.viewbox-button-default {
  fill: $dark-gray;
}
.viewbox-button-default:hover {
  fill: $text-white;
}

.viewbox-button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
}
.viewbox-button-full-screen {
  position: absolute;
  top: 84px;
  right: 10px;
  z-index: 9;
}
.viewbox-button-next,
.viewbox-button-prev {
  color: $text-white;

  position: absolute;
  top: 50%;
  height: 128px;
  width: 128px;
  margin: -64px 0 0;
  z-index: 9;
}
.viewbox-button-next {
  right: 10px;
}
.viewbox-button-next,
.icon-style:hover {
  color: $text-white;
}
.viewbox-button-prev {
  left: 10px;
}
@media (max-width: 480px) {
  .viewbox-button-close {
    top: 0;
    right: 0;
  }
  .viewbox-button-next,
  .viewbox-button-prev {
    height: 81px;
    width: 81px;
    margin-top: -40px;
  }
  .viewbox-button-next {
    right: -15px;
  }
  .viewbox-button-prev {
    left: -15px;
  }
}

/* loader */
.viewbox-container .loader {
  widows: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -25px;
}
.viewbox-container .loader * {
  margin: 0;
  padding: 0;
}
.viewbox-container .loader .spinner {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 auto;
}
.viewbox-container .loader .double-bounce1,
.viewbox-container .loader .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $dark-gray;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.viewbox-container .loader .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
