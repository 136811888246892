.loader-main {
  background-color: transparent;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  .loader-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}
