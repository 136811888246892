@import "./variables.scss";

.btn:focus {
  box-shadow: none !important;
}
.superSmallText {
  font-size: $super-small-font;
}
.smallText {
  font-size: $small-font;
}
.midText {
  font-size: $midium-font;
}
.largeText {
  font-size: $large-font;
}
.textPrimaryColor {
  color: $auth-primary-color;
}
.textGrayColor {
  color: $gray-color;
}
.textBlackColor {
  color: $black-text-color;
}
.pWithMargin {
  margin: 10px 5px;
}
.rightAlignText {
  text-align: right;
}
.leftAlignText {
  text-align: left;
}
.cursorPointer {
  cursor: pointer;
}
.linkDecoreRemove {
  text-decoration: none;
}
.h1WithoutTopMargin {
  margin-top: 0px;
}
.boldText {
  font-weight: 600;
}
.visibilityHidden {
  visibility: hidden;
}
.f-18 {
  font-size: 18px;
}
.f-16 {
  font-size: 16px !important;
}

/* page banner css for mobile */
.popupheading h2 {
  color: $black-text-color;
}
@media (max-width: 991px) {
  .navbar-toggler-icon {
    margin-top: -12px;
  }
}
@media (max-width: 576px) {
  .banner-img {
    position: relative;
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $auth-primary-color;
      content: "";
    }
    .pagename {
      z-index: 99;
      text-align: center;
      h1 {
        color: $text-white !important;
      }
      a {
        color: $text-white !important;
      }
    }
  }
  .popupheading {
    h2 {
      font-size: 18px !important;
    }
  }
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
    justify-content: flex-start !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

//change default scrollbar
// scroll change
::-webkit-scrollbar {
  height: 12px;
  width: 10px;
  border-radius: 5px;
  background: $light-gray-7;
  display: none;
}
::-webkit-scrollbar-button {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background: $dark-gray-1;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-thumb:hover {
  background: $slate-gray;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-thumb:active {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
  border-radius: 0px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
