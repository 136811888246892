@import "./variables.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: "Roboto";
  font-weight: normal;
  font-size: 16px;
  color: $dark-gray;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

.main-block {
  width: 100%;
  height: 100%;
  align-items: center;
}

.main-block:after {
  content: "";
  display: table;
  clear: both;
}

.wrap-box.active {
  width: 100%;
  padding: 20px 0;
}

.skill-pd {
  padding: 60px 30px;
  background: url("../img/home.png") no-repeat;
  background-size: cover;
  background-position: center right;
  height: 100vh;
  width: 100%;
  display: table;
}

.skill-text-area {
  position: relative;
  display: table-cell;
  height: 100vh;
  vertical-align: middle;
  padding-left: 20px;
}

.wrap-box.active .points {
  background: $pink-color;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  float: left;
}

.wrap-box.active .points span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  color: $blue-color;
  line-height: 2.4;
  font-size: 42px;
  font-family: "Roboto";
  font-weight: bold;
}

.wrap-box.active .points-content {
  font-size: 42px;
  font-weight: bold;
  color: $text-white;
  font-family: "Roboto";
  float: left;
  padding-left: 15px;

  @media (max-width: 992px) {
    font-size: 22px;
  }
}

.wrap-box.active .points-content p {
  margin: 0;
  line-height: 2.3;
  color: $text-white;
}

.normal-points {
  background: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  float: left;
  border: 4px solid $text-white;
}

.normal-points span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  color: $pink-color;
  line-height: 2.2;
  font-size: 42px;
  font-family: "Roboto";
  font-weight: bold;
}

.normal-points-content {
  font-size: 42px;
  font-weight: normal;
  color: $text-white;
  font-family: "Roboto";
  float: left;
  padding-left: 15px;
}

.normal-points-content p {
  margin: 0;
  line-height: 2.3;
  color: $text-white;
}

.wrap-box {
  display: flex;
  width: 100%;
  padding: 20px 0;
  align-items: center;
}

.wrap-box:hover .normal-points {
  background: $pink-color;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  float: left;
  border: 4px solid transparent;
  transition: all 0.5s ease-in-out;
}

.wrap-box:hover .normal-points span {
  color: $blue-color;
}

.wrap-box:hover .normal-points-content {
  font-weight: bold;
  transition: all 0.5s ease-in-out;
}

.right-block {
  text-align: center;
  padding: 10px 45px;
}

.main-logo {
  display: inline-block;
  text-align: center;
  margin: auto;
}

.nav-tabs {
  margin-bottom: 1rem;
}

.right-block-content {
  font-family: "Roboto";
}

.right-block-content h2 {
  font-weight: 500;
  font-size: 36px;
  text-align: left;
  color: $blue-color;
  padding-bottom: 15px;
}

.right-block-content .nav-pills .nav-link.active,
.right-block-content .nav-pills .show > .nav-link {
  color: $blue-color;
  background-color: transparent;
}

.right-block-content .nav-link {
  margin: 0 30px 0 0px;
  padding: 15px 0;
  position: relative;
  color: $dark-gray;
  font-size: 24px;
}

.right-block-content .nav-link:hover {
  color: $blue-color;
  transition: all 0.3s ease-in-out;
}

.right-block-content .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: $pink-color;
  width: 100%;
  height: 2px;
}

.info {
  color: $light-purple-color;
  font-size: 12px;
  font-weight: normal;
}

.info i {
  margin-right: 10px;
}

.info-wrap {
  display: block;
  text-align: left;
  padding: 15px 0 16px 0;
}

#inputSuccess1.form-control {
  border: 1px solid $blue-color;
  text-align: center;
  color: $red-shade-black-color;
}

#inputSuccess1.form-control::placeholder {
  color: $red-shade-black-color;
  opacity: 1;
  text-align: center;
}

#inputSuccess1.form-control:focus {
  box-shadow: none;
}

.acc-title {
  text-align: left !important;
  color: $blue-color !important;
}

.buttons-wrapper {
  display: inline-block;
  width: 100%;
}

.srch-btn,
.prev-add {
  float: left;
  background: $blue-color !important;
  color: $text-white !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}

.prev-add {
  float: right;
}

.srch-btn:focus,
.prev-add:focus {
  box-shadow: none;
}

.srch-btn i,
.prev-add i {
  margin-right: 5px;
}

.m-t-100 {
  margin-top: -100px;
}

.has-success {
  margin-bottom: 30px;
}

.srch-btn:hover,
.prev-add:hover {
  background: transparent !important;
  border: 1px solid $blue-color !important;
  color: $blue-color !important;
  transition: all 0.5s ease-in-out !important;
}

/* landing page */

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.2;
  text-align: center;
  color: $blue-color;
}

.has-search .form-control:focus {
  box-shadow: none !important;
}

.has-search .form-control {
  padding-left: 2.375rem;
  width: 400px;
  color: $dark-gray;
  font-family: "Roboto";
  font-size: 14px;
}

.has-search .form-control::placeholder {
  color: $dark-gray;
}

.form-group.has-search {
  margin-bottom: 0;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar.navbar-expand-lg.navbar-light {
  border-bottom: 1px solid #dbdbdb;
}

.navbar-expand-lg .navbar-nav .nav-link {
  text-transform: uppercase;
}

.nav-link img {
  margin-top: -2px;
}

/* accordion css */

.wrap {
  max-width: 1536px;
  width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.content-li {
  display: block;
  width: 100%;
}

.title-wrapper {
  display: flex;
  border-top: 1px solid $light-gray-9;
  border-bottom: 1px solid $light-gray-9;
}

.home-title {
  align-self: center;
  margin: 0;
  vertical-align: middle;
  font-family: "Roboto";
  color: $blue-color;
  padding-left: 15px;
}

.home-icon {
  display: inline-block;
  padding: 15px 25px 20px 0;
  position: relative;
}

.home-icon::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: $light-gray-9;
  width: 1px;
}

.card-lable {
  text-align: left;
  margin: 0;
  padding: 0.15rem !important;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 16px;
  color: $dark-gray;
}
.left-label {
  text-align: left;
  margin: 0;
  padding: 0.75rem !important;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 16px;
  color: $dark-gray;
}

.left-label .form-control:focus {
  border-color: $blue-color;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.1);
}

.right-data {
  justify-content: start;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  padding: 0.75rem !important;
  color: $darker-gray;
  margin-top: 5px;
  text-align: right;
}

.home-title {
  width: 100%;
}

.inner-ul2 {
  width: 20% !important;
}

.acc-title {
  text-decoration: none !important;
  font-family: "Roboto";
  color: $dark-gray;
}

#accordion {
  margin-top: 10px;
}

.card-header {
  background: transparent;
  border-bottom: none;
}

.acc-title {
  width: 100%;
  text-align: left;
  position: relative;
}

.acc-title:after {
  font-family: Fontawesome;
  content: "\f077";
  float: right;
  font-size: 16px;
  font-weight: 300;
  transition: all 300ms;
}

.acc-title.collapsed:after {
  font-family: Fontawesome;
  content: "\f078";
  transition: all 300ms;
}

.table thead th {
  border-bottom: none;
}

.table td,
.table th {
  border-top: none;
  vertical-align: middle;
}

.content-wrapper {
  float: left;
  width: 70%;
  padding-right: 30px;
}

.acc-table {
  width: 100%;
  float: left;
  margin: 8px 0;
}

.table-responsive-sm.table-responsive-md.table-responsive-lg.table-responsive-xl {
  display: inline-block;
  width: 100%;
}

.acc-slider {
  width: 30%;
  float: right;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
}

.acc-slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: $light-gray-9;
  height: 100%;
  width: 1px;
}

.acc-slider p {
  padding: 20px;
  font-family: "Roboto";
  font-size: 16px;
  color: $dark-gray;
  margin: 0;
}

.hero-slider .item {
  max-width: 30%;
  margin: auto;
  text-align: center;
}

.owl-carousel .owl-stage-outer {
  padding-bottom: 45px;
}

.owl-nav {
  display: inline-block;
  position: absolute;
  top: -50px;
  right: 24px;
}

.owl-prev,
.owl-next {
  display: inline-block;
}

.owl-next {
  float: right;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: $dark-gray;
  border: none;
  padding: 0 5px !important;
  font: inherit;
  font-size: 20px;
}

.slider-counter {
  text-align: center;
}

.owl-prev {
  background: url("../img/left-arrow.svg") no-repeat;
}

.owl-item {
  background-color: transparent;
  color: $text-white;
  text-align: center;
  padding: 0;
}

.owl-prev {
  float: left;
  font-size: 20px;
  text-transform: uppercase;
  padding: 20px;
}

.owl-prev span {
  background: transparent !important;
}

.owl-next {
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  padding: 0;
}

.owl-next span {
  background: transparent !important;
}

.owl-dots {
  counter-reset: slides-num;
  position: absolute;
  top: 80%;
  left: 35%;
  font-family: "Roboto";
  font-size: 12px;
  color: $dark-gray;
}

.owl-dots::after {
  content: counter(slides-num);
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
  position: absolute;
  margin-left: -5px;
}

.owl-dot {
  display: inline-block;
  counter-increment: slides-num;
  margin-right: 5px;
}

.owl-dot span {
  display: none;
}

.owl-dot.active:before {
  content: counter(slides-num) "/";
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.info-box {
  display: inline-block;
  width: 100%;
  display: none;
}

.broker-remarks {
  width: 70%;
  display: table;
  position: relative;
  bottom: -10px;
  padding: 25px 0;
  border-top: 1px solid $light-gray-9;
  display: none;
}

.info-box h6,
.broker-remarks h6 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  color: $dark-gray;
  padding: 0 10px;
  float: left;
  width: 15%;
}

.info-box p,
.broker-remarks p {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
  color: $darker-gray;
  width: 85%;
  float: right;
  margin: 0;
}

.read-more-show,
.read-more-hide {
  font-family: "Roboto";
  color: $dark-gray;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  padding: 15px 0;
}

.hide {
  display: none;
}

/* sidebar css */

aside {
  max-width: 350px;
  display: inline-block;
  border: 1px solid $light-gray-9;
  border-radius: 8px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}

.sidebar-box {
  display: flex;
  background: $blue-color;
  padding: 20px;
  width: 100%;
}

.sidebar-circle {
  border: 2px solid $pink-color;
  background-color: transparent;
  position: relative;
  height: 64px;
  width: 64px;
  display: block;
  border-radius: 50%;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 30px;
  float: left;
}

.sidebar-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 52px;
  width: 52px;
  background: $pink-color;
  display: block;
  border-radius: 50%;
}

.sidebar-box.side-box .sidebar-circle {
  border: 2px solid $dark-gray;
}

.sidebar-box.side-box:hover .sidebar-circle,
.side-box.active .sidebar-circle {
  border: 2px solid $pink-color;
}

.sidebar-box.side-box .sidebar-circle::before {
  background: transparent;
}

.sidebar-circle span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: block;
  line-height: 2;
  color: $blue-color;
}

.sidebar-box.side-box .sidebar-circle span {
  color: $dark-gray;
}

.sidebar-box.side-box:hover .sidebar-circle::before,
.side-box.active .sidebar-circle::before {
  background: $pink-color;
}

.sidebar-box.side-box:hover .sidebar-circle span,
.side-box.active .sidebar-circle span {
  color: $blue-color;
}

.circle-title {
  float: left;
  line-height: normal;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  color: $text-white;
  padding-left: 15px;
  position: relative;
  z-index: 1;
}

.circle-title:hover {
  color: $text-white;
}

.side-box {
  background: transparent;
  border-bottom: 1px solid $light-gray-9;
  align-items: center;
}

.side-box .circle-title {
  color: $dark-gray;
  transition: all 0.3s linear;
}

.side-box:hover,
.side-box.active {
  background: $blue-color;
  transition: all 0.4s linear;
}

.side-box:hover .circle-title,
.side-box.active .circle-title {
  color: $text-white;
  text-decoration: none;
}

.card2 {
  width: 75%;
  float: right;
  margin-top: 10px;
}

.card2 .table {
  margin: 0;
}

.card2 .table thead tr {
  background: rgba(29, 34, 169, 0.04);
  font-family: "Roboto";
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid $light-gray-9;
  text-align: left;
}

.card2 .table thead tr th {
  color: $light-purple-color;
  font-weight: normal;
}

.card2 .table tbody tr .right-data {
  text-align: left !important;
  color: $dark-gray;
  font-weight: normal;
}

.input-table {
  border: 1px solid $darker-gray;
  border-radius: 4px;
}

.left-label .btn-group,
.left-label .btn-group .main-btn {
  width: 100%;
}

.left-label .select-dd {
  background: none;
  border: 1px solid $darker-gray;
  color: $dark-gray;
  text-align: left;
  height: 38px;
}

.left-label .select-dd {
  background: none;
  border: 1px solid $darker-gray;
  color: $black-color;
  text-align: left;
  height: 38px;
  width: 100%;
  border-radius: 3px;
  -webkit-appearance: none;
  padding: 0 10px;
}

.left-label .btn-group .main-btn::after {
  border-right: 2px solid $dark-gray;
  transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-top: 2px solid $dark-gray;
  display: block;
  border-left: none;
  right: 20px;
  top: 10px;
}

.left-label .dropdown-menu.show {
  width: 100%;
}

.lft-drpdwn {
  position: relative;
}

.lft-drpdwn::after {
  border-right: 2px solid $dark-gray;
  transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-top: 2px solid $dark-gray;
  display: block;
  border-left: none;
  right: 30px;
  top: -5px;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.gj-datepicker.gj-datepicker-bootstrap.gj-unselectable.input-group {
  width: 100% !important;
}

#datepicker,
.gj-datepicker-bootstrap [role="right-icon"] button {
  border: 1px solid $darker-gray !important;
}

#datepicker {
  border-right: none !important;
}

.btn-outline-secondary {
  border-left: none !important;
}

.card-header {
  padding: 5px 0;
}

.card3 .card-header {
  border-bottom: 1px solid $light-gray-9;
}

.lb-location {
  font-size: 18px;
  color: $dark-gray;
  margin: 0;
  font-family: "Roboto";
  font-weight: normal;
  text-align: start;
}

.lb-location small {
  display: block;
  padding-top: 15px;
}

.check-box-wrapper {
  list-style: none;
  padding: 15px;
  margin: 0;
}

/* custom checkboxess */

input[type="checkbox"].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type="checkbox"].css-checkbox + label.css-label,
input[type="checkbox"].css-checkbox + label.css-disable-label,
input[type="checkbox"].css-checkbox + label.css-label.clr {
  padding-left: 30px;
  height: 17px;
  text-align: left;
  display: inline-block;
  line-height: 17px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 17px;
  vertical-align: middle;
  cursor: pointer;
}

input[type="checkbox"].css-checkbox:checked + label.css-label,
input[type="checkbox"].css-checkbox:checked + label.css-disable-label,
input[type="checkbox"].css-checkbox + label.css-label.chk {
  background-position: 0 -17px;
}

label.css-label {
  background-image: url(../img/csscheckbox.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 30px;
}
.property-checkbox {
  margin-bottom: 25px !important;
}
label.css-disable-label {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  background-image: url(../img/csscheckbox.png);
  opacity: 0.75;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 30px;
}

.css-label {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
}

.check-box-wrapper li td {
  width: 250px;
}

.form-check-label {
  color: $dark-gray;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: normal;
}

.add-btn {
  background: $blue-color;
  color: $text-white;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
}

.info-label {
  padding-bottom: 15px;
}

.save-box {
  padding: 15px;
  border-top: 1px solid $light-gray-9;
}

.card:last-child {
  margin-bottom: 30px;
}

.add-btn img {
  padding-right: 5px;
}

.comparable-title {
  width: 60%;
}

.range-cma {
  align-self: center;
  margin: 0;
  vertical-align: middle;
  font-family: "Roboto";
  color: $blue-color;
  padding-left: 15px;
  font-size: 20px;
  border-left: 1px solid $light-gray-9;
  padding: 20px;
  background: rgba(29, 34, 169, 0.04);
  display: none;
}

.add-cmparables {
  margin-top: 5px;
  display: initial !important;
}

.cmparables-wrapper {
  float: left;
  width: 50%;
}

.map-img {
  float: right;
  width: 50%;
}

.cmparables-wrapper .card .card-header {
  font-family: "Roboto";
  font-size: 18px;
  color: $dark-gray;
  padding: 15px 20px;
  border-bottom: 1px solid $light-gray-9;
}

.cmparables-wrapper .card .card-body {
  background: $pale-blue;
  flex-flow: column;
}

.cmparables-wrapper .card .card-body .sidebar-circle {
  border: 2px solid $pink-color;
  background-color: transparent;
  position: relative;
  height: 120px;
  width: 120px;
  display: block;
  border-radius: 50%;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 30px;
  float: left;
  margin-bottom: 30px;
}

.cmparables-wrapper .card .card-body .sidebar-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 98px;
  width: 98px;
  background: $pink-color;
  display: block;
  border-radius: 50px;
}

.cmparables-wrapper .card .card-body .sidebar-circle span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: block;
  line-height: 3.6;
  color: $blue-color;
}

.search-list .input-group-text {
  background: $text-white !important;
  border-right: none !important;
}

.search-list .form-control {
  border-left: none !important;
}

.add-to-list-box p {
  float: left;
  width: 40%;
  font-family: "Roboto";
  font-size: 12px;
  color: $dark-gray;
  margin: 0;
  margin-right: auto;
}

.comparable-list {
  width: 100%;
}

.comparable-list .card .card-body {
  flex-flow: column;
  padding: 0;
}

.comparable-list .card .card-header {
  font-family: "Roboto";
  font-size: 18px;
  color: $dark-gray;
  padding: 15px 20px;
  border-bottom: 1px solid $light-gray-9;
}

.comparable-list .card .card-body p {
  margin: 0;
  padding: 15px;
  font-family: "Roboto";
  font-size: 18px;
  color: $dark-gray;
}

.card-footer {
  background: #f5f5f5;
}

.card-footer .save-box {
  border: none;
  padding: 0;
}

.card-footer .save-box .add-btn {
  background: $dark-gray;
}

footer {
  width: 100%;
  height: 60px;
  background: #728292;
}

.cmp-list {
  padding: 0;
}

table td {
  padding: 0.15rem !important;
}

.home-id {
  display: flex;
  padding: 10px;
}

.home-id p {
  padding: 0 15px !important;
  font-size: 17px !important;
  font-family: "Roboto";
  font-weight: 500 !important;
  color: $blue-color !important;
}

.home-grid {
  width: 50%;
}

.home-grid:nth-child(odd) {
  float: left;
  padding-left: 10px;
  border-right: 1px solid $light-gray-9;
  border-bottom: 1px solid $light-gray-9;
}

.home-grid:nth-child(even) {
  border-right: none;
  background: $pale-blue;
  float: left;
  border-bottom: 1px solid $light-gray-9;
}

.home-grid:nth-last-child(2):nth-child(odd),
.home-grid:nth-child(even):last-child {
  border-bottom: none !important;
}

.home-status {
  padding-bottom: 30px;
}
.mls_main {
  display: flex;
  align-items: center;
  min-width: 100%;
}
.mls_main span {
  margin-left: 5px !important;
}
.home-status p,
.home-status-time {
  font-size: 16px;
  font-family: "Roboto";
  padding: 0 !important;
}

.home-status-time p {
  padding: 0 !important;
}

.status-box {
  font-family: "Roboto";
  font-weight: 500;
  color: $remove-red;
  font-size: 16px;
}

.status-time {
  font-family: "Roboto";
  font-weight: 400;
  color: $dark-gray;
  font-size: 14px;
}

.status-adjustments {
  font-family: "Roboto";
  font-weight: 500 !important;
  color: $blue-color !important;
  font-size: 15px !important;
  text-align: left;
}

.offer {
  background: rgba(27, 224, 67, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(27, 224, 67, 0.3)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 4px;
}

.offer p {
  font-size: 11px !important;
  color: $dark-gray;
}

.btns-wrapper {
  display: flex !important;
  flex-direction: column;
}

.offer span {
  font-size: 26px !important;
  font-weight: 500;
  font-family: "Roboto";
  color: $custom-color;
  padding-left: 10px;
}

.address-label {
  padding-bottom: 5px !important;
}

.home-info span {
  font-family: "Roboto";
  font-size: 16px;
  color: $darker-gray;
  font-weight: 500;
  padding-left: 15px;
  display: block;
}

.home-grid-left .table .info-data {
  color: $darker-gray;
  font-weight: 500;
  padding-top: 0;
}

.adjustment-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.adjustment-box label {
  margin: 0 !important;
}

.adjustment-box button {
  margin: 0 !important;
  float: right;
  background: transparent;
  color: $remove-red;
  border: 1px solid $remove-red;
}

.adjustment-box button i {
  transform: rotate(50deg);
}

.minus-rate {
  background: linear-gradient(
    to right,
    rgba(248, 49, 49, 0.3) 0%,
    white 100%
  ) !important;
}

.minus-rate > span {
  color: $remove-red !important;
}

.home-grid-right .offer,
.minus-rate .offer {
  background: rgba(248, 49, 49, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.home-grid-right .offer span,
.minus-rate .offer span {
  color: $remove-red;
}

.home-grid-right:last-child,
.home-grid-left:last-child {
  border-bottom: none;
}

.blue-btn {
  background: $blue-color !important;
}

.list-card-footer {
  background: transparent;
}

/* adjustments page */

.adjustment-wrapper {
  width: 100%;
  float: none;
}

.card-header nav {
  float: right;
}

.card-header nav ul {
  margin: 0;
}

.card-header nav ul li {
  padding: 0 10px;
}

.card-header nav ul li a {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  color: $dark-gray;
}

.card-header nav ul li a.active,
.card-header nav ul li a:hover {
  color: $blue-color;
}

.adjust-body {
  padding: 0 !important;
  background: transparent !important;
}

.ongoing-adjustment.d-flex.align-items-center {
  margin-left: 10px;
}

.cmp-list-box {
  border-right: 1px solid $light-gray-9;
  padding-bottom: 20px !important;
  background: $pale-blue-1;
  padding-left: 20px;
  width: 33.333333%;
}
.cmp-list-adjust-box {
  display: table-cell;
}
.cmp-list-box .home-id p {
  margin: 0;
  font-weight: normal !important;
  font-size: 18px !important;
}
.cmp-list-box .home-id span {
  padding: 0;
  font-size: 18px !important;
  font-family: "Roboto";
  font-weight: normal !important;
  color: $blue-color !important;
}
.no-bg {
  background: transparent !important;
}
.no-bg .ongoing-adjustment {
  padding-left: 5px;
  border-radius: 4px;
  margin-left: 15px;
  background: rgba(224, 224, 224, 0.5);
  background: -moz-linear-gradient(
    left,
    rgba(224, 224, 224, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(224, 224, 224, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(224, 224, 224, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(224, 224, 224, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(224, 224, 224, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.home-id .ongoing-adjustment p {
  font-size: 12px !important;
  font-family: "Roboto" !important;
  font-weight: normal !important;
  margin: 0 !important;
}
.img-holder {
  float: left;
  width: 50%;
}
.ongoing-info {
  float: right;
  padding: 0 15px;
  width: 50%;
}
.ongoing-info .home-status p {
  margin: 0;
  color: $dark-gray;
}
.ongoing-info .home-status {
  padding-bottom: 10px;
}
.ongoing-info .status-box {
  color: $darker-gray;
}
.ongoing-info .home-status-time p {
  color: $dark-gray;
}
.ongoing-info .status-time {
  color: $darker-gray;
  font-size: 14px;
}
.no-bg .home-id p {
  color: $darker-gray !important;
}
.no-bg .home-id span {
  color: $blue-color !important;
}
.home-header {
  padding: 0 !important;
}

.home-header .title-wrapper {
  border: none !important;
}

.adjust-slider {
  border: 1px solid $light-gray-9;
  border-radius: 4px;
}

.adjust-slider .owl-carousel .owl-dots.disabled,
.adjust-slider .owl-carousel .owl-nav.disabled {
  display: block !important;
}

.adjust-slider .owl-dots::after {
  content: counter(slides-num);
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
  position: absolute;
  margin-left: -15px;
  color: $blue-color;
}

.adjust-slider .owl-dot.active::before {
  content: counter(slides-num) "/";
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  color: $blue-color;
}

.adjust-slider .owl-nav {
  display: inline-block;
  position: absolute;
  top: 87%;
  right: 15px;
}

.adjust-slider .owl-nav button.owl-next,
.adjust-slider .owl-nav button.owl-prev,
.adjust-slider button.owl-dot {
  color: $blue-color !important;
  font: inherit;
  font-size: 20px;
}

.mapouter1 {
  text-align: right;
  height: 331px;
  width: 100%;
  border: 1px solid $light-gray-9;
  border-radius: 4px;
  overflow: hidden;
}

.gmap_canvas1 {
  overflow: hidden;
  background: none !important;
  height: 331px;
  width: 100%;
}

#gmap_canvas {
  height: 331px;
  margin-left: 0;
  width: 100% !important;
}

.adjust-info-box h6 {
  padding-top: 18px;
  padding-left: 20px;
}

.adjust-info-box p {
  font-size: 16px !important;
}

.adjusted-list-box h6 {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  margin-top: 5px;
}

.subject-property {
  margin-top: 30px;
}

.adjusted-list-box p {
  font-size: 18px !important;
}

.adjusted-list-box .home-id {
  padding: 0 15px 15px 15px;
}

.adjust-table thead tr {
  background: #1d22a90a;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid $light-gray-9;
  text-align: left;
}

.adjust-table thead tr th {
  color: $light-purple-color;
  font-weight: normal;
}

.adjust-table .form-control::placeholder {
  color: $dark-gray;
}

.adjust-table .right-data {
  text-align: left !important;
  color: $dark-gray;
  font-weight: normal;
}

.center-data {
  text-align: center !important;
  color: $dark-gray;
  font-weight: normal;
  font-family: "Roboto";
  font-size: 18px;
}

.down-price {
  color: $remove-red !important;
}

.table-check-box {
  display: block !important;
}

.adjust-table .lb-location small {
  padding-top: 0;
}

.chk-active {
  color: $blue-color;
}

.cmp-list-box.completed {
  background: none !important;
}

.ongoing {
  background: $pale-blue-1 !important;
}

.completed .ongoing-adjustment {
  margin-left: 10px;
  background: rgba(245, 213, 99, 0.5);
  background: -moz-linear-gradient(
    left,
    rgba(245, 213, 99, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  background: -webkit-linear-gradient(
    left,
    rgba(245, 213, 99, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(245, 213, 99, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(245, 213, 99, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(245, 213, 99, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 4px;
}

.completed .ongoing-adjustment span {
  padding-left: 10px !important;
}

.ongoing .ongoing-adjustment {
  background: none !important;
}

.ongoing .ongoing-adjustment p {
  color: $blue-color !important;
}

.home-id a {
  padding: 0 15px !important;
  font-size: 24px !important;
  font-family: "Roboto";
  font-weight: 500 !important;
  color: $blue-color !important;
}

.completed .home-id a {
  margin: 0;
  font-weight: normal !important;
  font-size: 18px !important;
  color: $blue-color !important;
}

.home-id a {
  padding: 0 15px !important;
  font-size: 18px !important;
  font-family: "Roboto";
  font-weight: normal !important;
  color: $blue-color !important;
}

.no-bg .home-id a {
  color: $blue-color !important;
}

.ongoing .home-id a,
.ongoing .home-id span {
  color: $blue-color !important;
  text-decoration: none;
}

.adjust-title {
  width: auto;
  padding: 20px;
  border-right: 1px solid $light-gray-9 !important;
}

.adjust-title:last-child {
  border-right: none !important;
}

.body-inner {
  display: inline-block;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid $light-gray-9;
  position: relative;
}

.summary-box {
  border-right: none !important;
  width: 22%;
  float: left;
}

.summary-box .home-id {
  padding-left: 0;
}

.summary-box .ongoing-info {
  float: right;
}
.comparable_property {
  border-bottom: 1px solid gray;
}

.summary-box2 {
  width: 25%;
  float: left;
}

.summary-box3 {
  width: 20%;
  float: left;
  height: 100%;
}

.net-ajusted-box {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  margin-top: 40px;
}

.net-adjust-inner {
  float: left;
}
.adjust_text {
  font-size: 22px !important;
}
.compared_property {
  font-size: 22px !important;
}
.change_percent {
  font-size: 14px !important;
}
.net-ajusted-box .offer {
  float: right;
}

.net-ajusted-box h4 {
  font-size: 30px;
  color: #555;
  font-weight: 500;
}

.net-ajusted-box a {
  font-size: 12px;
  color: $dark-gray;
}

.net-ajusted-box .offer p {
  font-size: 16px !important;
}
.ml_address {
  display: flex;
  align-items: center;
}
.net-ajusted-box .offer {
  background: rgba(248, 49, 49, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  background: -webkit-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.net-ajusted-boxxx .offer {
  background: rgba(248, 49, 49, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  background: -webkit-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.net-ajusted-box .offer span {
  color: $remove-red;
}

.btns-wrapper {
  display: block;
}
.btn-wrap {
  padding: 10px !important;
}
.btns-wrapper button {
  float: left;
  background: transparent;
  color: $remove-red;
  border: 1px solid $remove-red;
}

.btns-wrapper button i {
  transform: rotate(50deg);
}

.view-btn {
  background: $blue-color !important;
  color: $text-white !important;
  border: 1px solid $blue-color !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  margin-right: 10px;
  float: left;
  margin-bottom: 15px;
}

.summary-box2 .form-group {
  position: relative;
}

.summary-box2 .form-group img {
  position: absolute;
  top: 15px;
  left: 15px;
}

#myInput {
  padding: 10px 40px;
}

.body-inner:last-child {
  border-bottom: none;
}

.rec-wrapper {
  display: inline-block;
  width: 100%;
}

.rec {
  width: 50%;
  float: left;
  text-align: left;
}

.rec h5 {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.review-save-box {
  border-top: none !important;
  float: right;
}

.review-body .input-group.input-group-lg {
  padding-left: 15px;
}

.input-group-lg .form-control::placeholder {
  color: $dark-gray;
  opacity: 1;
  font-size: 30px;
  font-weight: 500;
}

.home-id .grey-title {
  color: $dark-gray !important;
}

.cmp-list-box .home-id .grey-title {
  color: $dark-gray !important;
}

/* generate report */

.gen-rep {
  float: none;
  width: 100%;
}

.rep-inc {
  width: 35%;
  float: left;
}

.rep-content-title {
  width: 65%;
}

.rep-content-title p {
  font-size: 12px;
  margin: 0;
}

.rep-box-body {
  padding: 0;
  background: transparent !important;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
}

.rep-box {
  width: 50%;
  float: left;
  border-right: 1px solid $light-gray-9;
  background: $pale-blue;
  height: 500px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.rep-box:last-child {
  border-right: none;
}

.rep-box-no-bg {
  background: transparent;
}

.select-all {
  display: inline-block;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid $light-gray-9;
}

.select-all label {
  margin: 0;
}

.adjust-slider .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  top: 90%;
  left: 0;
  font-family: "Roboto";
  font-size: 12px;
  color: $dark-gray;
}

/* generate report checkbox */

.select-all input[type="checkbox"].css-checkbox,
.select-choice input[type="checkbox"].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.select-all input[type="checkbox"].css-checkbox + label.css-label,
.select-all input[type="checkbox"].css-checkbox + label.css-label.clr,
.select-choice input[type="checkbox"].css-checkbox + label.css-label,
.select-choice input[type="checkbox"].css-checkbox + label.css-label.clr {
  padding-left: 30px;
  height: 16px;
  display: inline-block;
  line-height: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 16px;
  vertical-align: middle;
  cursor: pointer;
}

.select-all input[type="checkbox"].css-checkbox:checked + label.css-label,
.select-all input[type="checkbox"].css-checkbox + label.css-label.chk,
.select-choice input[type="checkbox"].css-checkbox:checked + label.css-label,
.select-choice input[type="checkbox"].css-checkbox + label.css-label.chk {
  background-position: 0 -16px;
}

.select-all label.css-label,
.select-choice label.css-label {
  background-image: url(../img/csscheckbox.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-choice {
  padding: 20px;
}

.select-choice label {
  width: 100%;
  display: block;
}

.select-all input[type="checkbox"].css-checkbox:checked + label.css-label,
input[type="checkbox"].css-checkbox + label.css-label.chk {
  color: $blue-color;
}

.cmp-list-range-cma {
  display: block;
}

.rec-price {
  display: none;
}

.review-range-block {
  display: block;
}

.form-row {
  margin-bottom: 15px !important;
}

.hidden-btn {
  visibility: hidden !important;
}

.cmp-add {
  margin-top: 10px;
  display: table;
}

.cmp-list-box:last-child {
  border: none;
}

.row-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.net-ajusted-box .offer span {
  font-size: 30px;
  font-weight: 500;
  font-family: "Roboto";
  color: $custom-color;
  padding-left: 10px;
}

.net-ajusted-box .offer {
  background: rgba(27, 224, 67, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  background: -webkit-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(27, 224, 67, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 4px;
}

.net-ajusted-box.minus-rate .offer span {
  color: $remove-red;
}

.net-ajusted-box.minus-rate .offer {
  background: rgba(248, 49, 49, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  background: -webkit-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(248, 49, 49, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: $black-color;
  /* Fallback color */
  background-color: $black-color;
  /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 200px;
  text-align: center;
  color: $light-gray-4;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: $light-gray-3;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: $light-gray-2;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

#myModal {
  background: $black-color;
}

#myModal .modal-content {
  max-width: 30% !important;
}

.home-img-box {
  width: 100%;
  overflow: hidden;
}

.home-img-box img {
  max-width: 100%;
}

.cmp-body {
  overflow: scroll !important;
}

#inputSuccess1.form-control::placeholder {
  opacity: 0.4;
}

.cmp4-img {
  width: unset !important;
}

.ongoing-info.cmp4-imfo {
  float: left !important;
}

.completed .ongoing-adjustment p {
  color: $blue-color !important;
  text-align: left;
}

.form-control {
  color: $black-color !important;
}

.adjst-change {
  float: none;
  width: 100%;
}

.info-change {
  float: none;
  width: 100%;
  margin-top: 15px;
}

.ongoing .ongoing-adjustment.nxt-adjst {
  border-radius: 4px !important;
  background: $light-gray-9 !important;
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    $light-gray-9 0%,
    #ededed 100%
  ) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    $light-gray-9 0%,
    #ededed 100%
  ) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    $light-gray-9 0%,
    #ededed 100%
  ) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.ongoing .ongoing-adjustment.adjust-done p {
  color: $blue-color !important;
  text-align: left !important;
}

.ongoing .ongoing-adjustment.adjust-done {
  border-radius: 4px !important;
  background: linear-gradient(
    to right,
    rgba(255, 5, 232, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}

.ongoing .ongoing-adjustment.nxt-adjst p {
  color: $dark-gray !important;
  text-align: left !important;
}

.main-btn {
  border: 1px solid $light-gray-9 !important;
  background: transparent;
}

#datepicker,
.gj-datepicker-bootstrap [role="right-icon"] button {
  border: 1px solid $light-gray-9 !important;
}

.main-btn:focus,
#datepicker:focus,
.gj-datepicker-bootstrap [role="right-icon"] button:focus {
  border-color: $blue-color !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}

.adjust-slider .owl-dots {
  display: none;
}

.adjust-slider .owl-nav {
  left: 0;
  width: 100%;
  display: block !important;
}

.input-wrapper span {
  position: absolute;
  right: 20px;
  top: 5px;
}

.top-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999999;
  max-width: 1474px;
  margin: 0 auto;
  right: 0;
  border: 1px solid $light-gray-9;
  border-radius: 4px;
  background: $light-gray-3;
}

.public-remarks {
  padding: 18px;
  display: inline-block;
  width: 100%;
  align-items: left;
}

.public-remarks h2 {
  color: $blue-color;
  font-family: "Roboto";
  font-size: 20px;
  text-align: left;
}

.public-remarks p {
  text-align: left;
  font-size: 16px !important;
}

.modal {
  background: $black-color !important;
}

.modal-backdrop {
  background: transparent !important;
  z-index: 0 !important;
}

/* upload csv design css */
.inputDnD {
  border: 0.1em dashed currentColor;
  border-radius: 8px;
}

.inputDnD span {
  position: absolute;
  text-align: center;
  transform: translate(-50%, 0px);
  left: 50%;
  margin-top: 15px;
  color: currentColor;
  font-size: 28px;
}

.inputDnD .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 4em;
  outline: none;
  visibility: visible !important;
  cursor: pointer;
  display: block;
  opacity: 0;
}

.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  padding-top: 1.5em;
  text-align: center;
  opacity: 1;
  visibility: visible;
}

/* upload csv design css */
.inputDnD {
  border: 0.1em dashed currentColor;
  border-radius: 8px;
}

.inputDnD span {
  position: absolute;
  text-align: center;
  transform: translate(-50%, 0px);
  left: 50%;
  margin-top: 15px;
  color: currentColor;
  font-size: 28px;
}

.inputDnD .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 4em;
  outline: none;
  visibility: hidden;
  cursor: pointer;
}

.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  padding-top: 1.5em;
  text-align: center;
  opacity: 1;
  visibility: visible;
}

.property-list ul {
  list-style: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.property-list ul li {
  color: $blue-color;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: unset;
}

.property-list ul li input[type="radio"] {
  position: absolute;
  display: none;
}

.property-list ul li label {
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  padding: 0 0 0 20px;
  margin: 10px auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  text-align: left;
}

.property-list ul li:hover label {
  color: $blue-color;
}

.property-list ul li .radiobutton {
  display: block;
  position: absolute;
  border: 1px solid $blue-color;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  top: 12px;
  left: 0px;
}

.property-list ul li:hover .radiobutton {
  border: 4px solid $blue-color;
}

.property-list ul li .radiobutton::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 6px;
  width: 6px;
  top: 3px;
  left: 3px;
  margin: auto;
}

.property-list input[type="radio"]:checked ~ .radiobutton::before {
  background: $blue-color;
  margin: auto;
}

.property-list input[type="radio"]:checked ~ .radiobutton {
  border: 1px solid $blue-color;
}

.property-list input[type="radio"]:checked ~ label {
  color: $blue-color;
}

/* headeer css 29-7022 */

.btn-blue {
  background: $blue-color !important;
  border: 1px solid $blue-color !important;
  color: $text-white !important;
  transition: all 0.5s ease-in-out !important;
  height: 40px !important;
}

.btn-blue:hover {
  background: transparent !important;
  border: 1px solid $blue-color !important;
  color: $blue-color !important;
  transition: all 0.5s ease-in-out !important;
}

.btn-pink {
  background: $pink-color !important;
  border: 1px solid $pink-color !important;
  color: $text-white !important;
  transition: all 0.5s ease-in-out !important;
}

.btn-pink:hover {
  background: transparent !important;
  border: 1px solid $pink-color !important;
  color: $pink-color !important;
  transition: all 0.5s ease-in-out !important;
}

/**/
.c-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
.add_known_main {
  padding-bottom: 17px !important;
}
/* .add_know_btn{
  margin-left: 30px;
} */
.comparables_cardMain {
  margin-bottom: 10px !important;
}
@media (max-width: 767px) {
  .c-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1360px) {
  .normal-points-content p {
    font-size: 22px !important;
  }

  .wrap-box.active .points-content {
    font-size: 22px !important;
  }
}

@media (max-width: 800px) {
  .normal-points span {
    line-height: 18px;
    font-size: 14px !important;
  }

  .wrap-box.active .points span {
    line-height: 18px;
    font-size: 14px !important;
  }

  .wrap-box.active .points {
    width: 40px !important;
    height: 40px !important;
  }

  .wrap-box .points {
    width: 40px !important;
    height: 40px !important;
  }

  .normal-points {
    width: 40px !important;
    height: 40px !important;
  }
}

.gm-style .gm-style-iw-c {
  padding: 15px !important;
}

.gm-style .gm-style-iw-c .gm-style-iw-d {
  overflow: unset !important;
}
