@import "../../Common/styles/main.scss";
.main-block-login {
  background-color: $blue-color;
  height: 100vh;
  position: relative;
  background-position: right bottom;
  background-image: url("../../Common/img/home.png");
  background-repeat: no-repeat;
  height: auto;
  min-height: 100vh;

  .inner-block {
    padding-top: 15px;
    text-align: left;
  }
  .login-block {
    width: 450px;
    max-width: 96%;
    margin: 4% auto;
    h2 {
      color: $text-white;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .logo {
      height: 150px;
      width: 150px;

      img {
        height: 100%;
        width: 100%;
        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }
  }
  .auth-form {
    width: 100%;
    label {
      color: $text-white;
    }
  }
  .form-block {
    background-color: $text-white;
    border-radius: 8px;
    margin-top: 40px;
    padding: 15px;
    input {
      width: 100%;
      border: 1px solid $blue-color;
      border-radius: 4px;
      height: 40px;
      padding-left: 10px;
      margin-top: 1rem;
      margin-bottom: 0;

      &::-webkit-input-placeholder {
        color: $red-shade-black-color;
      }
      &:-moz-placeholder {
        color: $red-shade-black-color;
      }
      &::-moz-placeholder {
        color: $red-shade-black-color;
      }
      &:-ms-input-placeholder {
        color: $red-shade-black-color;
      }
    }
  }

  .form-link {
    color: $blue-color;
    justify-content: flex-end;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
    text-decoration: none;
  }

  .form-button {
    background: $navy-blue !important;
    color: $text-white !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
  }
  .tabs-blcok {
    display: flex;
    color: $text-white;
    font-family: "Roboto";
    font-size: 22px;

    .title-tab-login {
      padding-bottom: 10px;
      transition: 0.2s;
      cursor: pointer;
      color: $text-white;
      border-bottom: 2px solid $pink-color;
      margin-right: 20px;
    }
    .title-tab-login-inactive {
      padding-bottom: 10px;
      transition: 0.2s;
      cursor: pointer;
      margin-right: 20px;
      color: $dark-gray;
      border-bottom: 2px solid transparent;
    }
  }

  .error-block {
    font-size: 12px;
    color: $red-color;
  }
  .error-input {
    padding-bottom: 0 !important;
  }
}
